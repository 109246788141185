import "./scss/style.scss";
import 'bootstrap';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import { appear } from "../node_modules/jquery.appear/jquery.appear";

$('header').load("header.html");
$('footer').load("footer.html");
$('#block-formar-parte').load("block-formar-parte.html");

if ($.contains(document.body, document.getElementById('slider-main'))) {

    var slider_main = tns({
        container: '#slider-main',
        items: 1,
        loop: true,
        slideBy: 'page',
        mode: 'gallery',
        autoplay: true,
        speed: 1000,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        lazyload: true,
        mouseDrag: true,
    });
}

if ($.contains(document.body, document.getElementById('slider-carousel'))) {

    var slider_news = tns({
        container: '#slider-carousel',
        items: 1,
        gutter: 20,
        loop: true,
        autoplay: true,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        mouseDrag: true,
        responsive: {
            480: {
                items: 2
            },
            768: {
                items: 2
            },
            850: {
                items: 3
            },
            991: {
                items: 5
            },
            1200: {
                items: 5
            }
        }
    });

    document.querySelector('.btn-slider-carousel.btn-left').onclick = function() {
        slider_news.goTo('prev');
    };

    document.querySelector('.btn-slider-carousel.btn-right').onclick = function() {
        slider_news.goTo('next');
    };
}